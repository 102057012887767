//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DateFilter",
  props: {
    id: null,
    period: { start: "", end: "" },
    header: null,
  },
  data() {
    return {
      listPeriod: { start: "", end: "" },
      dialog: false,
    };
  },
  watch: {
    period: function () {
      this.listPeriod = Object.assign(this.listPeriod, this.period);
    },
  },
  methods: {
    submitDateFilter: function () {
      this.$emit("submit-date-filter", this.listPeriod);
      this.dialog = false;
    },
    setId: function (prefix) {
      return this.id + prefix;
    },
  },
  beforeMount() {
    this.listPeriod = Object.assign(this.listPeriod, this.period);
  },
};
