//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AssignMultiple from "../Parts/AssignMultiple";
import TaskShowSettings from "../Parts/TaskShowSettings";
import DateFilter from "./Filters/DateFilter.vue";
import FunnelFilter from "./Filters/FunnelFilter.vue";
import PlanBoardModel from "./PlanBoardModel";
import { DayPilot } from "daypilot-pro-vue";
import { mapActions } from "vuex";
import AppointmentModel from "../Models/AppointmentModel";
import SelectModel from "../Models/SelectModel";
import { date } from "quasar";
import DynamicForm from '@/components/Forms/DynamicForm'
import firebase from "@/firebase";
import AbModel from '@/components/Models/AbModel'
import {EventBus} from "@/event-bus";

export default {
  name: "Scheduler",
  components: {
    AssignMultiple,
    TaskShowSettings,
    DateFilter,
    FunnelFilter,
    DynamicForm
  },
  props: {
    id: {},
    config: {},
    tasks: {},
    orders: {},
    users: {},
    technicians: {},
    techniciansList: {},
    globalHolidays: {},
    absences: {},
    dateStart: {},
    dateEnd: {},
    userViewSettings: {},
    currentDate: {},
    locale: {},
    localeDayPilot: {},
    filters: {},
    filtersDropDownValuesWorkOrders: {},
    filtersDropDownValuesAppointments: {},
    planboardViewSettings: {},
    selectedViewProps: {},
    noViewUsers: {}
  },
  data() {
    return {
      resources: [],
      activeBtnDay: true,
      activeBtnWeek: false,
      activeBtnMonth: false,
      taskAssignMultiple: {},
      dateStartSheduler: "",
      showModalAppointmentForm: false,
      showAssignMultiple: false,
      showTaskShowSettings: false,
      dateInput: this.formatDate(new Date().toISOString().substr(0, 10)),
      exportArea: "viewport",
      showNonBusiness: false,
      showDayPilotSetting: false,
      allowMultiMove: false,
      allowMultiResize: false,
      typeOfResourcesFilter: null,
      showModalWorkOrderForm: false,
      workOrderRecordID: null,
      appointmentID: null,
      appointmentParams: {},
      viewModel: this.selectedViewProps,
      viewName: '',
      showSaveAsDialog: false,
      showViewSettings: false,
      addViewMode: false,
      showModalDeleteView: false,
      noViewUsersModel: [],
    };
  },

  methods: {
    ...mapActions("subscribe", ["updatePlanBoardFilters"]),
    appointmentAddMove(args) {
      const data = args.e.data;
      if (!data.id) {
        data.order = this.getOrderByID(data.orderID);
        data.user = this.getUserByID(data.resource);
        const model = new AppointmentModel();
        model.createFromDayPilot(data);
      } else {
        args.multimove.forEach((a) =>
          new AppointmentModel().updateFromDayPilot(a.event.data)
        );
      }
    },

    async addNewAppointment(args) {
      const model = new AbModel();
      const user = await model.getByID(args.resource, 'users');
      this.appointmentParams = {
        user: {ID: user.ID, name: user.name},
        plannedStartDateTime: this.toTimestampValue(args.start.toDateLocal()),
        plannedEndDateTime: this.toTimestampValue(args.end.toDateLocal()),
        status: SelectModel.appointmentStatus.planned,
      };
      this.showModalAppointmentForm = true;
    },

    toTimestampValue(val) {
      return firebase.firestore.Timestamp.fromMillis(Date.parse(val));
    },

    resetShowModalAppointmentForm()  {
      this.showModalAppointmentForm = false;
      this.appointmentID = null;
      this.appointmentParams = {};
    },

    appointmentResize(args) {
      args.multiresize.forEach((a) =>
        new AppointmentModel().updateFromDayPilot(a.event.data)
      );
    },
    getOrderByID(id) {
      return this.orders.find((wo) => wo.ID === id);
    },
    getUserByID(id) {
      return this.users.find((us) => us.id === id);
    },
    submitPlanDateFilter: function (period) {
      this.updatePlanBoardFilters({ tasks: { period } });
    },
    submitPlanFilter: function (filters) {
      this.updatePlanBoardFilters({ tasks: { filters } });
    },
    resetPlanFilter: function () {
      this.updatePlanBoardFilters({
        tasks: PlanBoardModel.getEmptyFilter({
          filtersDropDownValuesWorkOrders: this
            .filtersDropDownValuesWorkOrders,
          filtersDropDownValuesAppointments: this
            .filtersDropDownValuesAppointments,
        }),
      });
    },
    formatDate: function (date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    dayBack: function () {
      this.changeDate(this.scheduler.startDate.addDays(-1));
    },
    dayStart: function () {
      this.dateStartSheduler = DayPilot.Date.parse(
        this.dateInput,
        "dd-MM-yyyy"
      );
      this.changeDate(this.dateStartSheduler);
    },
    dayAdd: function () {
      this.changeDate(this.scheduler.startDate.addDays(1));
    },
    changeDate(date) {
      this.scheduler.startDate = date;
      this.scheduler.update();
      this.dateInput = this.scheduler.startDate.toString("dd-MM-yyyy");
    },
    loadLinks: function () {
      /*
      const uniqContainers = [];
      let links = [];
      const containers = {};
      this.tasks.forEach((t) => {
        if (t && !uniqContainers.includes(t.container)) {
          uniqContainers.push(t.container);
          containers[t.container] = [];
        }
      });
      uniqContainers.forEach((u) => {
        const container = this.tasks
          .filter((t) => t && t.container === u)
          .map((c) => (c = c.id));
        containers[u].push(...container);
      });

      for (const c of Object.values(containers)) {
        for (let i = 1; i < c.length; i++) {
          if (c.length > 1) links.push({ from: c[i - 1], to: c[i] , style: 'solid', color: "pink"});
        }
      }
      */
      const links = []; // disable the event links as they don't make much sense at this moment, we need to rethink this functionality.
      return links;
    },
    loadEvents: function () {
      // one update in the loadResources()
      //this.scheduler.update({ events: this.tasks, links: links });
    },
    loadResources: function () {
      //console.time('loadResources');
      this.scheduler.update({ resources: this.technicians, events: this.tasks });
      //console.timeEnd('loadResources');
    },
    currentSeparatorsUpdateByTimer: function () {
      this.scheduler.separators = [
        {
          color: "#415F66",
          location: new DayPilot.Date(),
          layer: "UnderEvents",
          width: 1,
        },
      ];
      this.scheduler.update();
    },

    dayScheduler: function () {
      this.activeBtnDay = true;
      this.activeBtnWeek = false;
      this.activeBtnMonth = false;
      Object.assign(this.control, {
        cellWidthSpec: "Auto",
        cellWidthMin: 40,
        cellDuration: 30,
        timeHeaders: [
          { groupBy: "Day", format: "dddd dd MMMM yyyy" },
          { groupBy: "Hour", format: "H" },
        ],
        scale: "CellDuration",
        days: 3,
        startDate: this.dateStartSheduler,
        businessBeginsHour: 6,
        businessEndsHour: 20,
        // eslint-disable-next-line
        scrollTo: new DayPilot.Date.today(),
        //infiniteScrollingMargin: 1,
        //infiniteScrollingStepDays: 1,
      });

      this.loadResources();
      this.loadEvents();
      this.control.showCurrentTime = true;
      //this.control.init();
    },
    weekScheduler: function () {
      this.activeBtnDay = false;
      this.activeBtnWeek = true;
      this.activeBtnMonth = false;

      Object.assign(this.control, {
        //cellWidthSpec: "Auto",
        cellWidthSpec: "Fixed",
        cellWidth: 20,
        cellDuration: 60,
        timeHeaders: [
          { groupBy: "Month" },
          { groupBy: "Week" },
          { groupBy: "Day", format: "dddd dd MMMM yyyy" },
          { groupBy: "Hour", format: "H" }
          //{groupBy: "Cell", format: "dddd"},
          //{groupBy: "Cell", format: "d MMMM"}
        ],
        scale: "CellDuration",
        days: 28,
        startDate: this.dateStartSheduler,
        scrollTo: new DayPilot.Date.today(),
        infiniteScrollingEnabled: true,
        infiniteScrollingMargin: 3,
        infiniteScrollingStepDays: 10,
      });

      this.loadResources();
      this.loadEvents();
      //this.control.init();
    },
    monthScheduler: function () {
      this.activeBtnDay = false;
      this.activeBtnWeek = false;
      this.activeBtnMonth = true;

      Object.assign(this.control, {
        //cellWidthSpec: "Auto",
        cellWidthSpec: "Fixed",
        cellWidth: 60,
        timeHeaders: [
          { groupBy: "Month" },
          { groupBy: "Week" },
          { groupBy: "Cell", format: "ddd" },
          { groupBy: "Cell", format: "d MMM" },
        ],
        scale: "Day",
        // eslint-disable-next-line
        days: 90, //DayPilot.Date.today().daysInMonth(),
        startDate: this.dateStartSheduler,
        // eslint-disable-next-line
        scrollTo: new DayPilot.Date.today(),
        infiniteScrollingMargin: 1,
        infiniteScrollingStepDays: 10,
      });

      this.loadResources();
      this.loadEvents();
      //this.control.init();
    },
    receiveTaskShowSettings: function (fields) {
      this.$emit("receiveTaskShowSettings", fields);
    },
    receiveAssignedMultiple: function (assignMultiple) {
      this.$emit("receiveAssignedMultiple", assignMultiple);
      this.showAssignMultiple = false;
    },
    receiveCancelAssignedMultiple: function () {
      this.showAssignMultiple = false;
    },
    applyResourcesSelect: function () {
      const selectedRows = this.scheduler.rows.selection.get();
      const selectedRowsName = [];
      selectedRows.forEach((r) => selectedRowsName.push(r.name));
      this.typeOfResourcesFilter = "select";
      this.scheduler.rows.filter(selectedRowsName);
      if (this.viewModel.ID) this.onSaveView()
    },
    clearResourcesSelect: function () {
      if (this.typeOfResourcesFilter === "select") {
        this.scheduler.rows.filter([]);
        this.scheduler.rows.selection.clear();
      }
    },
    resourceFilter: function (query) {
      this.typeOfResourcesFilter = "filter";
      this.scheduler.rows.filter(query); // see dp.onRowFilter below
      this.scheduler.rows.selection.clear();
    },
    taskFilter: function (query) {
      if (query) {
        const finded = this.scheduler.events.find(function (e) {
          const match =
            e.text().toUpperCase().indexOf(query.toUpperCase()) > -1;
          return match;
        });
        this.scheduler.multiselect.clear();
        if (finded) {
          this.scheduler.multiselect.add(finded);
          this.scheduler.events.scrollIntoView(finded);
        }
      } else {
        this.scheduler.multiselect.clear();
      }
    },
    exportToJpg(ev) {
      ev.preventDefault();
      this.scheduler.exportAs("jpeg", { area: this.exportArea }).download();
    },
    resetShowModalWorkOrderForm() {
      this.showModalWorkOrderForm = false;
      this.workOrderRecordID = null;
    },
    initContextMenu() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const app = this;
      return new DayPilot.Menu({
        theme: "BouwBit_Scheduler_Menu",
        items: [
          {
            text: this.$t("planBoard.buttons.select"),
            onclick: function () {
              app.multiselect.add(this.source);
            },
          },
          {
            text: this.$t("common.buttons.delete"),
            onClick: function (args) {
              const e = args.source;
              new AppointmentModel().deleteFromCalendar(e.data.id);
            },
          },
          {
            text: "-",
          },
          {
            text: this.$t("planBoard.buttons.assignMore"),
            onClick: function (args) {
              const e = args.source;
              app.taskAssignMultiple = e.data;
              app.showAssignMultiple = true;
            },
          },
          {
            text: "-",
          },
          {
            text: this.$t("planBoard.buttons.settings"),
            onClick: function () {
              app.showTaskShowSettings = true;
            },
          },
          {
            text: "-",
          },
          {
            text: this.$t("planBoard.buttons.changeStatus"),
            items: [
              {
                text: this.$t("planBoard.buttons.toBeConfirmed"),
                onClick: function (args) {
                  const e = args.source;
                  const data = {
                    status: SelectModel.appointmentStatus.toBeConfirmed,
                    delayed: false,
                    warning: false,
                  };
                  new AppointmentModel().update(e.data.id, data);
                },
              },
              {
                text: this.$t("planBoard.buttons.planned"),
                onClick: function (args) {
                  const e = args.source;
                  new AppointmentModel().update(e.data.id, {
                    status: SelectModel.appointmentStatus.planned,
                  });
                },
              },
              {
                text: this.$t("planBoard.buttons.traveling"),
                onClick: function (args) {
                  const e = args.source;
                  new AppointmentModel().update(e.data.id, {
                    status: SelectModel.appointmentStatus.traveling,
                  });
                },
              },
              {
                text: this.$t("planBoard.buttons.inExecution"),
                onClick: function (args) {
                  const e = args.source;
                  new AppointmentModel().update(e.data.id, {
                    status: SelectModel.appointmentStatus.inExecution,
                  });
                },
              },
              {
                text: this.$t("planBoard.buttons.delayed"),
                onClick: function (args) {
                  const e = args.source;
                  new AppointmentModel().update(e.data.id, {
                    status: SelectModel.appointmentStatus.delayed,
                  });
                },
              },
              {
                text: this.$t("planBoard.buttons.done"),
                onClick: function (args) {
                  const e = args.source;
                  const data = {
                    status: SelectModel.appointmentStatus.done,
                    delayed: false,
                    warning: false,
                  };
                  new AppointmentModel().update(e.data.id, data);
                },
              },
            ],
          },
          {
            text: "-",
          },
          {
            text: this.$t("planBoard.buttons.copyAppointment"),
            onClick: function (args) {
              const appointment = Object.assign({}, args.source.data);
              new AppointmentModel().copyDayPilot(appointment);
            },
          },
          {
            text: this.$t("planBoard.buttons.openAppointment"),
            onClick: function (args) {
              app.showModalAppointmentForm = true;
              app.appointmentID = args.source.data.ID;
              //console.log(args.source.data);
            },
          },
          {
            text: this.$t("planBoard.buttons.sendNotification"),
            onClick: function (args) {
              const notification = {
                module: { ID: "workOrders", name: "workOrders" },
                relatedTo: {
                  ID: args.source.data.wo.id,
                  name: args.source.data.wo.name
                    ? args.source.data.wo.name
                    : "no value",
                },
                user: args.source.data.resource,
                title: "Plannings wijziging",
                message:
                  app.$t("planBoard.headers.appointment") +
                  " " +
                  args.source.data.wo.number +
                  " is gewijzigd, check aub",
              };
              app.$emit("receiveSendNotification", notification);
            },
          },
          {
            text: this.$t("planBoard.buttons.addComment"),
            onClick: function (arg) {
              const currentCommentValue = arg.source.data.remarks
                ? arg.source.data.remarks
                : "";
              // eslint-disable-next-line
              DayPilot.Modal.prompt(`${currentCommentValue}`, "", {
                theme: "modal_default",
              })
                //DayPilot.Modal.prompt('Type a comment...','',{theme: "modal_default"})
                .then((modal) => {
                  if (typeof modal.result !== "undefined") {
                    new AppointmentModel().update(arg.source.data.id, {
                      remarks: modal.result,
                    });
                  }
                });
            },
          },
        ],
      });
    },
    initContextMenuResource() {
      const app = this;
      const groupName =  this.$t('planBoard.headers.groupName');
      const resourceWillBeDeleted =  this.$t('planBoard.headers.resourceWillBeDeleted');
      const commonBtnOk = this.$t("common.buttons.ok");
      const commonBtnCancel = this.$t("common.buttons.cancel")
      return new DayPilot.Menu({
        items: /*app.viewModel.ID ? */ [
          {
            text: this.$t('planBoard.buttons.addResourceToGroup'),
            onClick: function(rowArgs) {
              if (!app.viewModel.ID) {
                alert('Select or create View first')
                return
              }
              const row = rowArgs.source;

              if (!row.data.user) {
                return
              }
              DayPilot.Modal.prompt(`${groupName} ${row.data.groupName || ''}`, { okText: `${commonBtnOk}`, cancelText: `${commonBtnCancel}` }).then(function(args) {
                if (!args.result) {
                  return;
                }
                const currentRow = app.scheduler.rowlist.find(r => r.resource.id === row.data.id);
                currentRow.resource.user.planBoardUserGroup = args.result ;
                app.onSaveView();
              });
            }
          },
          {
            text: this.$t('planBoard.buttons.deleteResource'),
            onClick: function(rowArgs) {
              if (!app.viewModel.ID) {
                alert('Select or create View first')
                return
              }
              const row = rowArgs.source;

              if (!row.data.user) {
                return
              }
              DayPilot.Modal.confirm(`"${row.data.user.name}" ${resourceWillBeDeleted}`, { okText: `${commonBtnOk}`, cancelText: `${commonBtnCancel}` }).then(function(args) {
                if (!args.result) {
                  return;
                }
                const currentRow = app.scheduler.rowlist.find(r => r.resource.id === row.data.id);
                currentRow.resource.user.deleted = true ;
                app.onSaveView();
              });
            }
          }
        ] /*: [{text: "To assign user to view group select or create view  first"}]*/
      })
    },
    initDropTarget() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const app = this;
      const drop = document.getElementsByClassName("j-planboard-orders-container")[0];
      drop.ondragover = function (ev) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "copy";
      };
      drop.onmouseup = function(ev) {
        //console.log('drop.onmouseup', ev);
      };
      drop.ondrop = function (ev) {
        const data = ev.dataTransfer.getData("daypilot/external-item");
        const evt = JSON.parse(data);
        //console.log("drop data", evt);
        new AppointmentModel().dragAndDropFromDayPilot(evt);
        app.$emit('dragAndDropFromDayPilot');
      };
    },
    getShowedResources() {
      const showedResources = [];
      this.scheduler.rowlist.forEach(e => {
        if (!e.hidden && e.resource && e.resource.id && e.resource.user && !e.resource.user.deleted){
          showedResources.push(
            {
              group: e.resource && e.resource.user && e.resource.user.planBoardUserGroup || '-',
              id: e.resource.id
            })
        }
        if (e.resource && e.resource.user && e.resource.user.planBoardUserGroup) e.resource.user.planBoardUserGroup = '-';
        if (e.resource && e.resource.user && e.resource.user.deleted) e.resource.user.deleted = false;
      });

      if (this.noViewUsersModel.length) {
        this.noViewUsersModel.forEach(u => showedResources.push({group: '-', id: u.id}))
      }

      return showedResources;
    },
    getOnCreateViewResourses(){
      const flatResources = [];
      this.scheduler.rowlist.forEach(e => {
        if (!e.hidden && e.resource && e.resource.id && e.resource.user){
          flatResources.push(
            {
              group: '-',
              //group: e.resource && e.resource.user && e.resource.user.planBoardUserGroup || '-',
              id: e.resource.id
            })
        }
        if (e.resource && e.resource.user && e.resource.user.planBoardUserGroup) e.resource.user.planBoardUserGroup = '-';
        if (e.resource && e.resource.user && e.resource.user.deleted) e.resource.user.deleted = false;
      });

      return flatResources;
    },
    onCreateView(){
      if (this.viewName) {
        const data = {
          id: null,
          view: {
            name: this.viewName,
            users: this.getOnCreateViewResourses(),
            period: this.getSchedulerPeriod()
          },
        };
        this.clearResourcesSelect();
        this.resourceFilter('')
        this.$emit('onSaveView', data);
        this.showViewSettings = false;
        this.viewModel = { ID: '', name: '', users: [] };
        this.viewName = '';
      }
    },
    onSaveView(){
      if (this.viewModel.ID) {
        const data = {
          id: this.viewModel.ID,
          view: {
            name: this.viewModel.name,
            users: this.getShowedResources(),
            period: this.getSchedulerPeriod()
          },
        };
        this.clearResourcesSelect();
        this.resourceFilter('')
        this.$emit('onSaveView', data);
        this.viewName = '';
        this.noViewUsersModel = []
      } else alert ('Select or create View first')
    },
    onDeleteView(){
      const data = {
        id: this.viewModel.ID,
      };
      this.clearResourcesSelect();
      this.resourceFilter('')
      this.$emit('onDeleteView', data);
      this.viewModel = { ID: '', name: '', users: [] };
    },
    onShowDefaultView(){
      this.viewModel = { ID: '', name: '', users: [] };
      this.onChangeView()
    },
    onChangeView(){
      this.clearResourcesSelect();
      this.resourceFilter('')
      this.$emit('onChangeView', this.viewModel.ID);
    },
    getSchedulerPeriod () {
      let period = 'day';
      if (this.activeBtnDay) {
        period = 'day'
      } else if (this.activeBtnWeek) {
        period = 'week'
      } else if (this.activeBtnMonth) {
        period = 'month'
      }
      return period
    }
  },
  timers: {
    currentSeparatorsUpdateByTimer: {
      time: 60 * 1000,
      autostart: true,
      repeat: true,
    },
  },
  computed: {
    scheduler: function () {
      return this.control;
    },
  },
  watch: {
    tasks: function () {
      this.loadEvents();
    },
    technicians: function () {
      this.loadResources();
    },
    locale: function () {
      if (this.scheduler) {
        this.scheduler.locale = this.locale;
        this.control.contextMenu = this.initContextMenu();
        this.scheduler.update();
      }
    },
    dayPilotDateStart: function () {
      // eslint-disable-next-line
      var start = DayPilot.Date.parse(this.dateStart, "yyyy-MM-dd");
      // eslint-disable-next-line
      var end = DayPilot.Date.parse(this.dateEnd, "yyyy-MM-dd");
      if (start && end) {
        this.dateStartSheduler = start;
        this.scheduler.startDate = this.dateStartSheduler;
        // eslint-disable-next-line
        this.scheduler.days = DayPilot.DateUtil.daysDiff(start, end);
        this.scheduler.update();

        this.dateInput = this.dateStart;
      }
    },
    dayPilotDateEnd: function () {
      // eslint-disable-next-line
      var start = DayPilot.Date.parse(this.dateStart, "yyyy-MM-dd");
      // eslint-disable-next-line
      var end = DayPilot.Date.parse(this.dateEnd, "yyyy-MM-dd");
      if (start && end) {
        this.dateStartSheduler = start;
        this.scheduler.startDate = this.dateStartSheduler;
        // eslint-disable-next-line
        this.scheduler.days = DayPilot.DateUtil.daysDiff(start, end);
        this.scheduler.update();
      }
    },
    showNonBusiness: function () {
      this.scheduler.showNonBusiness = this.showNonBusiness;
      this.scheduler.update();
    },
    allowMultiMove: function () {
      this.scheduler.allowMultiMove = this.allowMultiMove;
      this.scheduler.update();
    },
    allowMultiResize: function () {
      this.scheduler.allowMultiResize = this.allowMultiResize;
      this.scheduler.update();
    },
    selectedViewProps: function (view) {
      this.viewModel = Object.assign({}, view);

      const viewPeriod = this.viewModel && this.viewModel.period ? this.viewModel.period : 'day';
      switch (viewPeriod){
        case 'day':
          this.dayScheduler();
          break;
        case 'week':
          this.weekScheduler();
          break;
        case 'month':
          this.monthScheduler();
          break;
      }
      this.scheduler.rowHeaderColumns = [
        {
          title:
            '<div style="padding-left: 10px"> View: ' +
            this.viewModel.name  || this.$t("planBoard.headers.name") +
            '</div>',
          width: 200
        },
      ];
      this.scheduler.update();
    }
  },

  mounted: function () {
    this.activeBtnDay = true;
    this.activeBtnWeek = false;
    this.activeBtnMonth = false;
    // eslint-disable-next-line
    this.dateStartSheduler = DayPilot.Date.today();
    // eslint-disable-next-line
    this.control = new DayPilot.Scheduler(this.id, {}).init();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const app = this;

    Object.assign(this.control, {
      //cornerHtml: '<div>iuifdgfdgsdgsdfgfdsgfdsgfdsgfdsgsdgfdsgfdsgfdsgfdsgfdgfdgfdutretretretreyiu</div>',
      //cornerHtml: "******* left-up conner text",
      treeImageNoChildren: "img/Error.svg",
      treeIndent: 5,
      treePreventParentUsage: true,
      durationBarVisible: false,
      heightSpec: "Auto",
      //height: 380,
      showNonBusiness: app.showNonBusiness,
      businessWeekends: false,
      infiniteScrollingEnabled: true,
      infiniteScrollingStepDays: 300,
      locale: this.locale,
      theme: "BouwBit_Scheduler",

      allowMultiSelect: true,
      allowMultiMove: app.allowMultiMove,
      allowMultiResize: app.allowMultiResize,
      dragOutAllowed: true,
      eventStackingLineHeight: 100,
      rowMoveHandling: "Update",

      rowHeaderColumnsMode: "Legacy",
      rowHeaderColumns: [
        { title: this.$t("planBoard.headers.name"), width: 200 },
        //{title: '<div style="padding-left: 10px">n/h</div>', width: 50},
      ],

      onTimeRangeSelected: function (args) {
        app.addNewAppointment(args);
      },

      onRowFilter: function (args) {
        if (app.typeOfResourcesFilter === "filter") {
          if (
            args.row.name.toUpperCase().indexOf(args.filter.toUpperCase()) ===
            -1
          ) {
            args.visible = false;
          }
        }
        if (app.typeOfResourcesFilter === "select") {
          if (!args.filter.length) {
            args.visible = true;
            return;
          }
          if (!args.filter.includes(args.row.name)) {
            args.visible = false;
          }
        }
      },

      timeRangeSelectedHandling: "Enabled",
      eventHeight: 40,


      rowClickHandling: "Select",

      eventDoubleClickHandling: "Enabled",


      onEventDoubleClick: function (args) {
        app.showModalWorkOrderForm = true;
        app.workOrderRecordID = args.e.data.wo.ID;
      },

      eventMoveHandling: "Update",
      onEventMoved: function (args) {
        app.appointmentAddMove(args);
      },

      eventResizeHandling: "Update",
      onEventResized: function (args) {
        app.appointmentResize(args);
      },

      eventClickHandling: "Select",
      onEventClicked: function (args) {
        this.multiselect.add(args);
      },

      //eventHoverHandling: "Enabled",
      treeEnabled: true,

      // Render function

      onBeforeEventRender: function (args) {
        args.data.barColor = args.data.color;
        args.data.backColor = args.data.color;
        args.e.bubbleHtml = args.data.textBalloon;
        args.data.areas = [
          {
            right: 5,
            top: 5,
            height: 10,
            width: 10,
            backColor: "#FFF",
            cssClass: "draggable",
            style: "cursor: move"
            //style: "cursor: move; border: 2px solid grey"
          }
        ]
      },

      onAfterEventRender: function(args) {
        const data = args.e.data;
        const area = args.div.getElementsByClassName("draggable")[0];
        area.setAttribute("draggable", "true");
        area.addEventListener("dragstart", function(ev) {
          ev.dataTransfer.setData("daypilot/external-item", JSON.stringify(data));
        });
        area.addEventListener("mousedown", function(ev) {
          ev.stopPropagation();
        });
      },

      // TODO need to refactoring, very slowly x 10 time of the daypilot update

      onBeforeCellRender: function (args) {

        const dn = args.cell.start.getDayOfWeek();
        // eslint-disable-next-line

        if (dn % 2 === 0) {
          args.cell.backColor = "#f5f5f5";
        }

        if (
            dn === 6 ||
            dn === 0
        ) {
          args.cell.backColor = "#dddddd";
        }

      },

      onRowMoved: function(args) {
        const currentRow = app.scheduler.rowlist.find(r => r.resource.id === args.source.id);
        if (
          currentRow &&
          currentRow.resource &&
          currentRow.resource.user &&
          currentRow.resource.user.planBoardUserGroup &&
          args.target &&
          args.target.data &&
          args.target.data.user
        ) {
          currentRow.resource.user.planBoardUserGroup = args.target.data.user.planBoardUserGroup ;
          if(app.viewModel.ID) app.onSaveView()
        }
        else if (
          currentRow &&
          currentRow.resource &&
          currentRow.resource.user &&
          currentRow.resource.user.planBoardUserGroup &&
          args.target &&
          args.target.data &&
          !args.target.data.hasOwnProperty('user') &&
          args.target.data.id
        )
        {
          currentRow.resource.user.planBoardUserGroup = args.target.data.id ;
          if(app.viewModel.ID) app.onSaveView()
        }
      },
      onRowMoving: function(args) {
        if (!args.source.data.user) {
          args.position = "forbidden";
        }
      },
      // eslint-disable-next-line
      contextMenu: {},
      contextMenuResource: {},

    });

    this.control.contextMenu = this.initContextMenu();
    this.control.contextMenuResource = this.initContextMenuResource();

    this.dayScheduler();
    this.loadResources();
    this.loadEvents();
    this.currentSeparatorsUpdateByTimer();
    this.initDropTarget();
  },
};
