import { render, staticRenderFns } from "./Scheduler.vue?vue&type=template&id=0181e66d&"
import script from "./Scheduler.vue?vue&type=script&lang=js&"
export * from "./Scheduler.vue?vue&type=script&lang=js&"
import style0 from "./Scheduler.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QBtn,QSelect,QSeparator,QCheckbox,QCardActions,QList,QItem,QItemSection,QIcon,QInput,QSpace,QPopupProxy,QDate,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QBtn,QSelect,QSeparator,QCheckbox,QCardActions,QList,QItem,QItemSection,QIcon,QInput,QSpace,QPopupProxy,QDate})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
