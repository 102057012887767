//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DayPilot } from "daypilot-pro-vue";
import Orders from "./Orders.vue";
import Scheduler from "./Scheduler.vue";
import moment from "moment";
import PlanBoardModel from "./PlanBoardModel";
import { mapActions, mapGetters, mapState } from "vuex";
import db from "../../db";
import store from "../../store";
import SelectModel from "../Models/SelectModel";
import AppointmentModel from "../Models/AppointmentModel";
import ListValues from "@/components/Mixin/ListValues";
import {EventBus} from "@/event-bus";

export default {
  name: "planboard",
  components: { Orders, Scheduler },
  data() {
    return {
      sortDirect: true,
      dropDownValuesWorkOrders: {},
      dropDownValuesAppointments: {},
      filtersDropDownValuesWorkOrders: {},
      filtersDropDownValuesAppointments: {},
      module: "workOrders",
      doRefreshOrders: 0,
    };
  },
  computed: {
    dynamicModuleWO() {
      return this.dynamicModules ? this.dynamicModules['workOrders'] : {};
    },
    dynamicModuleAP() {
      return this.dynamicModules ? this.dynamicModules['appointments'] : {};
    },
    dynFieldsAll() {
      const woFields = this.dynamicModuleWO ? this.dynamicModuleWO.fields : {};
      //const apFields = this.dynamicModuleAP ? this.dynamicModuleAP.fields : {};
      return {...ListValues.locFields(), ...woFields};
    },
    tasks: function () {
      //console.log(this.appointmentsTasks);
      const result = PlanBoardModel.tasks(this.appointmentsTasks, {
        fields: this.userViewSettings,
        filters: this.filters,
        dynFields: this.dynFieldsAll,
      });
      return result;
    },

    selectedView() {
      return this.planboardViewSettings.find(e => e.ID === this.planboardDefaultViewID) || { ID: '', name: '', users: [] };
    },

    users() {
      const rawUsers = store.state.dynamic.users || [];
      if (!this.selectedView.ID) return rawUsers;
      const orderedUsers = this.selectedView.users && this.selectedView.users.map(e => rawUsers.find(user => user.ID === e.id)) || [];
      orderedUsers.map(user => {
        if (user) {
          const groupUser = this.selectedView.users.find(e => e.id === user.ID) || {};
          user.planBoardUserGroup = groupUser.group || ''
        }
      })

      return orderedUsers
    },

    noViewUsers(){
      const rawUsers = store.state.dynamic.users || [];
      if (!this.selectedView.ID) return [];
      return rawUsers.filter(user => !this.selectedView.users.find(e => user.ID === e.id)) || [];
    },

    resourses: function () {
      const result = PlanBoardModel.resourses(
        this.users.filter((t) => t && t.availableForPlanning),
        {
          tasks: this.tasks,
          filters: this.filters,
          absences: this.absences,
        },
        this.selectedView.ID
      );
      return result;
    },

    techniciansList: function () {
      return PlanBoardModel.techniciansList(this.resourses);
    },

    userViewSettings: function () {
      return PlanBoardModel.userViewSettings(this.userViewSettingsRaw, ListValues.getValidColumns(this.dynFieldsAll));
    },

    orders: function () {
      //console.log(this.appointmentsOrders);
      const appointmentsOrders = ListValues.transform(PlanBoardModel.orders(this.appointmentsOrders, {
        filters: this.filters,
      }), this.dynFieldsAll);
      return appointmentsOrders;
    },

    filters: function () {
      return PlanBoardModel.filters(this.filtersRaw, {
        filtersDropDownValuesWorkOrders: this.filtersDropDownValuesWorkOrders,
        filtersDropDownValuesAppointments: this
          .filtersDropDownValuesAppointments,
      });
    },

    counts: () => {
      const counts = {};
      return counts;
    },
    holidays: function () {
      return PlanBoardModel.holidays(this.holidaysRaw);
    },

    todayDate: function () {
      let today = DayPilot.Date.today().toString("dddd d MMMM yyyy", "nl-NL");
      today = today.charAt(0).toUpperCase() + today.slice(1); // First letter capital.
      return today;
    },

    weekNumber: function () {
      return DayPilot.Date.today().weekNumber();
    },
    absences: function () {
      return [];
    },

    ...mapState("subscribe", ["loading"]),
    ...mapState([
      "listUrgencies",
      "listStatuses",
      "listGroups",
      "listWOTypes",
    ]),
    ...mapState([
      "planUrgencies",
      "planStatuses",
      "planGroups",
      "planWOTypes",
    ]),
    ...mapState(["currentDate", "userViewSettingsRaw", "holidaysRaw"]),
    ...mapGetters(["locale", "language"]),
    ...mapGetters("user", { profile: "profile", loggedIn: "loggedIn" }),

    ...mapState("subscribe", ["filtersRaw"]),
    ...mapGetters("subscribe", ["appointmentsOrders", "appointmentsTasks"]),
    ...mapState(["dynamicModules"]),
    ...mapState('settings', ["planboardViewSettings", "planboardDefaultViewID"]),
  },
  watch: {},
  methods: {
    ...mapActions([
      "updatedPlanBoardTask",
      "updateAssignMultiple",
      "updateUserViewSettings",
    ]),
    ...mapActions(["copyWorkOrder", "sendNotification"]),
    ...mapActions("subscribe", [
      "updatePlanBoardFilters",
      "initPlanBoardFilters",
      "unbindAppointments",
    ]),
    ...mapActions('settings', ["getPlanboardViewSettings", "savePlanboardViewSettings", "deletePlanboardViewSettings",
      "getPlanboardDefaultViewID", "savePlanboardDefaultViewID"]),

    dragAndDropFromDayPilot() {
      this.doRefreshOrders++;
    },

    async getdropDownValuesWorkOrders() {
      const tempDropDownValues = {};
      const firePath = "tenants/" + store.state.tenantID + "/modules/";
      const result = await db.collection(firePath).doc("workOrders").get();
      const values = result.data() ? result.data().dropDownValues : {};

      for (const val in values) {
        if (Array.isArray(values[val])) {
          tempDropDownValues[val] = values[val].map((v) => {
            return {id: v, name: v};
          });
        }
      }
      this.dropDownValuesWorkOrders = tempDropDownValues;
      this.filtersDropDownValuesWorkOrders = this.dropDownValuesWorkOrders;
    },

    async getdropDownValuesAppointments() {
      const tempDropDownValues = {};
      const firePath = "tenants/" + store.state.tenantID + "/modules/";
      const result = await db.collection(firePath).doc("appointments").get();
      const values = result.data() ? result.data().dropDownValues : {};

      for (const val in values) {
        if (Array.isArray(values[val])) {
          tempDropDownValues[val] = values[val].map((v) => {
            return {id: v, name: v};
          });
        }
      }
      this.dropDownValuesAppointments = tempDropDownValues;
      this.filtersDropDownValuesAppointments = this.dropDownValuesAppointments;
    },
    receiveTaskShowSettings: function (fields) {
      this.updateUserViewSettings(fields);
    },
    receiveAssignedMultiple: function (assignMultiple) {
      this.updateAssignMultiple(assignMultiple);
    },
    updateDelayStatus: function () {
      this.appointmentsTasks
        .filter(wo => wo.status !== SelectModel.appointmentStatus.toBePlanned
          && wo.status !== SelectModel.appointmentStatus.done)
        .map((wo) => {
          let task = false;

          const endDate = wo.plannedEndDateTime.toDate();
          let warningTime;

          const estimatedTimeToFixHours = moment
            .duration(
              moment(wo.plannedEndDateTime.toDate()).diff(
                moment(wo.plannedStartDateTime.toDate())
              )
            )
            .hours();

          if (estimatedTimeToFixHours < 1) {
            warningTime = 0;
          } else if (estimatedTimeToFixHours < 2) {
            warningTime = 5; // [1..2)
          } else if (estimatedTimeToFixHours < 4) {
            warningTime = 10; // [2..4)
          } else if (estimatedTimeToFixHours < 8) {
            warningTime = 20; // [4..8)
          } else if (estimatedTimeToFixHours < 16) {
            warningTime = 30; // [8..16)
          } else {
            warningTime = 60; //  >= 16
          }

          /*
          console.log('---');
          console.log(wo.status);
          console.log(wo.number);
          console.log(new DayPilot.Date());
          console.log(new DayPilot.Date(endDate));
          console.log('---');
          console.log(warningTime);
          */

          if (wo.status !== SelectModel.appointmentStatus.done) {
            if (
              new DayPilot.Date() >
              new DayPilot.Date(endDate).addMinutes(-warningTime) &&
              !wo.warning &&
              wo.status !== SelectModel.appointmentStatus.delayed
            ) {
              task = {warning: true};
            }
            if (
              new DayPilot.Date() > new DayPilot.Date(endDate) &&
              wo.status !== SelectModel.appointmentStatus.delayed
            ) {
              task = {
                status: SelectModel.appointmentStatus.delayed,
                warning: false,
              };
            }
          } else {
            if (wo.warning) {
              task = {warning: false};
            }
          }
          if (task) {
            new AppointmentModel().update(wo.id, task);
          }
        });
    },
    receiveCopyWorkOrder: function (workOrder) {
      this.copyWorkOrder(workOrder);
    },
    receiveSendNotification: function (notification) {
      this.sendNotification(notification);
    },
    onChangeView(viewID){
      const data = {
        userViewID: viewID,
      };
      this.savePlanboardDefaultViewID(data)
        .then(()=>{
          this.getPlanboardDefaultViewID()
            .then(() => {
              this.getPlanboardViewSettings()
          });
      });
    },
    onSaveView(data) {
      this.savePlanboardViewSettings(data)
        .then(viewID => {
          this.onChangeView(viewID)
        })
    },
    onDeleteView(data) {
      this.deletePlanboardViewSettings(data)
        .then(() => {
          this.onChangeView('')
        })
    }
  },
  beforeMount() {
    //console.time('beforeMount');
    this.initPlanBoardFilters();
    this.getdropDownValuesWorkOrders();
    this.getdropDownValuesAppointments();
  },
  mounted(){
    this.getPlanboardDefaultViewID();
    this.getPlanboardViewSettings();

    document.body.addEventListener('click', function (e) {
      const className = e.target.className;
      if (className.includes('show-modal-form-default_')) {
        const userID =  className.replace('show-modal-form-default_', '');
        EventBus.$emit('show-modal-form-default', 'users', 'edit', userID);
      }
    });
  },
  beforeDestroy() {
    this.unbindAppointments();
  },
  timers: {
    //updateDelayStatus: { time: 10 * 1000, autostart: true, repeat: true }
    //updateDelayStatus: { time: 60 * 1000, autostart: true, repeat: true },
  },
};
