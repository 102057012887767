import AbModel, {Fields as AbModelFields} from "@/components/Models/AbModel";
import SelectModel from "@/components/Models/SelectModel";
import moment from "moment";
import firebase from "../../firebase";

export class Account {
  ID = "";
  name = "";
}

export class WorkOrder {
  ID = "";
  name = "";
}

export class Fields extends AbModelFields {
  name = ""; //M2000001
  actualEndDateTime: Date | null = null;
  actualStartDateTime: Date | null = null;
  plannedEndDateTime: Date = new Date(); // from drag end drop
  plannedStartDateTime: Date = new Date();
  status = SelectModel.appointmentStatus.toBePlanned;
  users: Array<string> = []; //["Ec7ZFwUC1pxUq4f8GeS8"],
  serviceEngineerMoveToAll = true;
  // always prefill with this data
  module = {
    ID: "workOrders",
    name: "workOrders",
  };
  // Related field to (module as selected in appointment.module)
  workOrder: WorkOrder = new WorkOrder();
  // Related field to (accounts)
  // copy over from workOrder.customer
  account: Account = new Account();
  // service fields
  warning = false;
  changed = false;
}

export class UpdatePlanedFields {
  plannedEndDateTime: Date = new Date(); // from drag end drop
  plannedStartDateTime: Date = new Date();
  users: Array<string> = []; //["Ec7ZFwUC1pxUq4f8GeS8"],
  warning = false;
  changed = false;
  resource = "";
  status = "";
}

class AppointmentModel extends AbModel {
  module = "appointments";

  fields(): Fields {
    return new Fields();
  }

  defaultValidation = {

  };

  createFromWorkOrder(param) {
    const task = this.fields();
    task.status = SelectModel.appointmentStatus.toBePlanned;
    task.workOrder.ID = param.ID;
    task.workOrder.name = param.name || "";
    this.convertToTimeStamp(task, this.fields());
    return this.set(this.convertToObject(task));
  }

  copyDayPilot(appointment) {
    const task = this.fields();
    for (const field in this.fields()) {
      if (field == 'id') continue;
      task[field] = appointment[field];
    }
    return this.set(this.convertToObject(task));
  }

  // drag and drop on daypilot
  async createFromDayPilot(event) {
    const hours = event.order.wo.estimatedTimeOnLocationHour;
    const appointment = await this.getByID(event.appoID, this.module);
    let start = event.start.toDateLocal();
    let end = event.end.toDateLocal();
    end = hours ? moment(start).add(hours, "h").toDate() : end;
    end = this.fireStore.Timestamp.fromDate(end);
    start = this.fireStore.Timestamp.fromDate(start);
    const task = new UpdatePlanedFields();
    task.status = SelectModel.appointmentStatus.planned;
    task.plannedStartDateTime = start;
    task.plannedEndDateTime = end;

    //console.log(event);
    task.users = (appointment as any).users.filter(u => u.availableForPlanning);
    if (task.users.indexOf(event.resource) === -1) {
      task.users.push(event.resource);
    }
    return this.update(event.appoID, this.convertToObject(task));
  }

  async updateFromDayPilot(event) {
    const task = new UpdatePlanedFields();
    const docID = event.ID.split('_||_')[0];
    const appointment = await this.getByID(docID, this.module);
    task.plannedStartDateTime = this.fireStore.Timestamp.fromDate(
      event.start.toDateLocal()
    );
    task.plannedEndDateTime = this.fireStore.Timestamp.fromDate(
      event.end.toDateLocal()
    );
    task.status = event.status;

    task.users = (appointment as any).users;
    if (task.users.length === 1) {
      task.users = [event.resource];
    } else {
      if (task.users.indexOf(event.resource) === -1) {
        task.users.push(event.resource);
      }
    }

    task.warning = false;
    task.changed = true;

    return this.update(docID, this.convertToObject(task));
  }

  async dragAndDropFromDayPilot(event) {
    const task = new UpdatePlanedFields();
    const docID = event.ID.split('_||_')[0];
    task.plannedStartDateTime = (firebase as any).firestore.Timestamp.now();
    task.plannedEndDateTime = (firebase as any).firestore.Timestamp.now();
    task.status = SelectModel.appointmentStatus.toBePlanned;
    task.users = [];
    return this.update(docID, this.convertToObject(task));
  }

  async deleteFromCalendar(id: string) {
    const docID = id.split('_||_')[0];
    const userID = id.split('_||_')[1];
    if (userID) {
      const record: any = await this.getByID(docID, this.module);
      record.users = record.users.filter((e) => e !== userID);
      if (record.users.length) {
        await this.update(docID, record);
      } else {
        return this.delete(docID);
      }
    } else {
      return this.delete(docID);
    }
  }

  update(id, data, callBack = (id) => {}) {
    const docID = id.split('_||_')[0];
    return super.update(docID, data, callBack);
  }

}

export default AppointmentModel;
