//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppointmentModel from "../Models/AppointmentModel";

export default {
  name: "AssignMultiple",
  props: {
    taskAssignMultiple: {},
    techniciansList: {},
    showAssignMultiple: null,
  },
  data() {
    return {
      assignedServiceEngineer: [],
      responsibleServiceEngineer: [],
      moveToAllFlag: false,
      showDialog: this.showAssignMultiple,
    };
  },

  methods: {
    async assignMultiple() {
      let id = this.taskAssignMultiple.id.split('_||_')[0];

      // split the appointment to each user
      if(!this.moveToAllFlag && this.assignedServiceEngineer.length > 1) {
        const model = new AppointmentModel();
        const appointmentSaved = await model.getByID(id, 'appointments');
        this.assignedServiceEngineer.map(u => {
          if (id) {
            const data = {id: id, users: [u], serviceEngineerMoveToAll: false};
            this.$emit("receiveAssignedMultiple", data);
            //console.log(data);
          } else {
            const data = { ...appointmentSaved, ...{id: id, ID: id, users: [u], serviceEngineerMoveToAll: false} };
            this.$emit("receiveAssignedMultiple", data);
          }
          id = null;
        });
      } else {
        const data = {id: id, users: this.assignedServiceEngineer, serviceEngineerMoveToAll: this.moveToAllFlag};
        this.$emit("receiveAssignedMultiple", data);
      }

      this.showDialog = false;
    },
    cancelAssignedMultiple: function () {
      this.$emit("receiveCancelAssignedMultiple");
      this.showDialog = false;
    },
  },
  computed: {},
  watch: {
    showAssignMultiple() {
      this.showDialog = this.showAssignMultiple;
    },
  },
  mounted: function () {
    if (typeof this.taskAssignMultiple.serviceEngineerMoveToAll !== "undefined") {
      this.moveToAllFlag = this.taskAssignMultiple.serviceEngineerMoveToAll;
    } else {
      this.moveToAllFlag = true;
    }
    this.assignedServiceEngineer = this.taskAssignMultiple.users;
  },
};
