//
//
//
//
//
//
//
//
//
//

import PlanBoardModel from "./PlanBoardModel";
import tableDragger from 'table-dragger';
import {mapActions, mapState} from "vuex";
import {DayPilot} from "daypilot-pro-vue";
import DynamicList from "@/components/Forms/DynamicList";

export default {
  components: {
    DynamicList
  },
  props: {
    data: {},
    dynamicModuleWO: {},
    dynamicModuleAP: {},
    dynFieldsAll: {},
    doRefreshOrders: null,
  },
  data: () => ({
    orderPagination: {
      page: 1,
      rowsPerPage: 30,
    },
    separator: "horizontal",
    showModalAppointmentForm: false,
    appointmentID: '',
    showDropdownSettings: false,
    columnSortEnabled: false,
    draggerHeaders: null,
    filterByName:null,
  }),
  methods: {
    async runMakeDraggable() {
      // TODO refactoring it (check when q-table finish rendering)
      setTimeout(this.makeDraggable, 500);
      setTimeout(this.makeDraggable, 1000 * 1);
      setTimeout(this.makeDraggable, 1000 * 2);
      setTimeout(this.makeDraggable, 1000 * 4);
      setTimeout(this.makeDraggable, 1000 * 6);
      setTimeout(this.makeDraggable, 1000 * 8);
      setTimeout(this.makeDraggable, 1000 * 10);
    },

    async makeDraggable() {
      const elements = document.getElementsByClassName("j-order-id");
      for (let i = 0; i < elements.length; i++) {
        DayPilot.Scheduler.makeDraggable({
          element: elements[i].parentElement.parentElement,
          orderID: elements[i].getAttribute("data-id"),
          appoID: elements[i].getAttribute("appo-id"),
          keepElement: true,
        });
      }
    },

    updatedPagination() {
      this.runMakeDraggable();
    },

    onRowDblClick(evt, row) {
      this.showModalAppointmentForm = true;
      this.appointmentID = row.id;
    },

    resetShowModalAppointmentForm() {
      this.showModalAppointmentForm = false;
    },

    doTableHeadersDragable(enabled) {
      if (this.draggerHeaders !== null) {
        this.draggerHeaders.destroy();
      }
      if (enabled) {
        const div = document.getElementsByClassName('my-sticky-header-column-table')[0];
        const el = div.getElementsByClassName('q-table')[0];
        this.draggerHeaders = tableDragger(el, {
          mode: 'column',
          dragHandler: 'th',
          onlyBody: true,
          animation: 300
        });
        this.draggerHeaders.on('drop', (from, to) => {
          this.onColumnDrop({from, to});
        });
      }
    },

    onColumnDrop(pos) {
      // -2 if we add first field ID
      pos.from -= 2;
      pos.to -= 2;
      const val = this.filtersSelectModel.columns[pos.from];
      this.filtersSelectModel.columns.splice(pos.from, 1);
      this.filtersSelectModel.columns.splice(pos.to, 0, val);
      this.saveColumnSettings();
      this.resetListFilter();
    },
  },

  computed: {
    ...mapState("subscribe", {dataLoading: "loading"}),

    getVisibleColumns() {
      return ['ID'].concat(this.visibleColumns)
    },

    dynFields() {
      return this.dynFieldsAll;
    },

    columnsOrders() {
      return [{
        name: 'ID',
        align: this.align,
        label: 'ID',
        field: 'ID',
      }].concat(this.columns);

    },

  },

  watch: {
    doRefreshOrders() {
      this.runMakeDraggable();
    }
  }

};
